import Head from 'next/head';
import { Box, Button, Container, Grid } from '@mui/material';
import { Budget } from '../components/dashboard/budget';
import { LatestOrders } from '../components/dashboard/latest-orders';
import { LatestProducts } from '../components/dashboard/latest-products';
import { Sales } from '../components/dashboard/sales';
import { TasksProgress } from '../components/dashboard/tasks-progress';
import { TotalCustomers } from '../components/dashboard/total-customers';
import { TotalProfit } from '../components/dashboard/total-profit';
import { TrafficByDevice } from '../components/dashboard/traffic-by-device';
import { DashboardLayout } from '../components/dashboard-layout';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import TotalForCostingProjects from '../components/dashboard/total-for-costing-projects';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import TotalCostedProjects from '../components/dashboard/total-costed-projects';
import TotalConstructingProjects from '../components/dashboard/total-constructing-projects';
import TotalDoneProjects from '../components/dashboard/total-done-projects';
import LatestNotifications from '../components/dashboard/latest-notifications';
import { useRouter } from 'next/router';
import { isJwtExpired } from 'jwt-check-expiration';
import IndexPageAddProjectButtonsRow from '../components/index/add-project-buttons';

function Page() {
  const { t } = useTranslation();
  const router = useRouter()
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const [renderLayout, setRenderLayout] = useState(false)

  const [firstLoad, setFirstLoad] = useState(true)
  const [userCanCost, setUserCanCost] = useState(false)
  const [userCanManager, setUserCanManager] = useState(false)
  const [userIsAccountant, setUserIsAccountant] = useState(false)
  const [userCanAddProject, setUserCanAddProject] = useState(false)
  const [userCanAddProjectWithouCost, setUserCanAddProjectWithoutCost] = useState(false)
  const [largeProjectViewTabs, setLargeProjectViewTabs] = useState([])
  //Index:
  //0 = For Costing
  //1 = Costed
  //2 = Constructing
  //3 = Done
  //4 = Deleted

  function getRoleRights(){
    const userDetail = jwtDecode(cookies.token)
    const roleID = userDetail['user_details']['role_id']

    let config = {
      method: 'get',
      url: '/api/roles/' + roleID,
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + cookies.token
      }
    };

    axios(config)
      .then(function (response) {
        const allResponse = response.data
        serializeRoleDetails(allResponse)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function serializeRoleDetails(data){
    let largeProjectTabs = []
    for (let i = 0; i<data['specific_roles'][0]['role_rights'].length; i++){
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCost"){
        setUserCanCost((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsAccountant"){
        setUserIsAccountant((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsManager"){
        setUserCanManager((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCanViewForCosting"){
        largeProjectTabs.push((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCanViewCosted"){
        largeProjectTabs.push((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCanViewConstructing"){
        largeProjectTabs.push((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCanViewDone"){
        largeProjectTabs.push((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCanViewDeleted"){
        largeProjectTabs.push((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjects"){
        const entityValue = data['specific_roles'][0]['role_rights'][i]['value']
        const binaryValue = parseInt(entityValue, 10).toString(2).padStart(4, "0")
        if (binaryValue.charAt(0) !== '1'){
          setUserCanAddProject(false)
        } else {
          setUserCanAddProject(true)
        }
      }
      if (data['specific_roles'][0]['role_rights'][i]['entity_name'] === "LargeProjectsCanAddWithoutCost"){
        setUserCanAddProjectWithoutCost((data['specific_roles'][0]['role_rights'][i]['value'] === 15))
      }
    }
    setLargeProjectViewTabs(largeProjectTabs)
    setRenderLayout(true)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (firstLoad) {
        if (typeof cookies.token === "undefined") {
          router.push("/login?route=" + router.asPath).then()
        } else {
          if (!isJwtExpired(cookies.token)) {
            getRoleRights()
          } else {
            router.push("/login?route=" + router.asPath).then()
          }
        }
        setFirstLoad(false)
      }
    }
  })

  return (
    <>
      <Head>
        <title>
          {t('dashboard')} | {t('app_name')}
        </title>
      </Head>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        {renderLayout &&
          <>
            <Container maxWidth={false}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                >
                  <h2 style={{textAlign: 'center'}}>{t('welcome_to_jb_ergon')}</h2>
                </Grid>
                <Grid
                  item={true}
                  md={12}
                  xs={12}
                >
                  <IndexPageAddProjectButtonsRow
                    canAddProject={userCanAddProject}
                    canAddProjectWithoutCost={userCanAddProjectWithouCost}
                  />
                </Grid>
              </Grid>
            </Container>
            <br/>
            <Container maxWidth={false}>
              <Grid
                container
                spacing={3}
              >
                {(largeProjectViewTabs[0]) &&
                  <>
                    <Grid
                      item
                      lg={3}
                      sm={6}
                      xl={3}
                      xs={12}
                    >
                      <TotalForCostingProjects/>
                    </Grid>
                  </>
                }
                {(largeProjectViewTabs[1]) &&
                  <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <TotalCostedProjects/>
                  </Grid>
                }
                {largeProjectViewTabs[2] &&
                  <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <TotalConstructingProjects/>
                  </Grid>
                }
                {largeProjectViewTabs[3] &&
                  <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <TotalDoneProjects/>
                  </Grid>
                }
              </Grid>
            </Container>
            <br/>
            <Container maxWidth={false}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <LatestNotifications
                    sx={{ height: '100%' }}
                  />
                </Grid>
              </Grid>
            </Container>
            <br/>
          </>
        }
        {/*<Container maxWidth={false}>*/}
        {/*  <Grid*/}
        {/*    container*/}
        {/*    spacing={3}*/}
        {/*  >*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      lg={3}*/}
        {/*      sm={6}*/}
        {/*      xl={3}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <Budget />*/}
        {/*    </Grid>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      xl={3}*/}
        {/*      lg={3}*/}
        {/*      sm={6}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <TotalCustomers />*/}
        {/*    </Grid>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      xl={3}*/}
        {/*      lg={3}*/}
        {/*      sm={6}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <TasksProgress />*/}
        {/*    </Grid>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      xl={3}*/}
        {/*      lg={3}*/}
        {/*      sm={6}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <TotalProfit sx={{ height: '100%' }} />*/}
        {/*    </Grid>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      lg={8}*/}
        {/*      md={12}*/}
        {/*      xl={9}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <Sales />*/}
        {/*    </Grid>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      lg={4}*/}
        {/*      md={6}*/}
        {/*      xl={3}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <TrafficByDevice sx={{ height: '100%' }} />*/}
        {/*    </Grid>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      lg={4}*/}
        {/*      md={6}*/}
        {/*      xl={3}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <LatestProducts sx={{ height: '100%' }} />*/}
        {/*    </Grid>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      lg={8}*/}
        {/*      md={12}*/}
        {/*      xl={9}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <LatestOrders />*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Container>*/}
      </Box>
    </>
  );
}

Page.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

export default Page;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      // Will be passed to the page component as props
    },
  };
}
