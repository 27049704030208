import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import { useTranslation } from 'next-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LatestsNotificationsReadAllNotificationsDialog({agreeClick, disagreeClick}) {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={disagreeClick}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('notifications')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('are_you_sure_you_want_to_read_and_close_all_notifications')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={disagreeClick} color="primary">
            {t('no')}
          </Button>
          <Button onClick={agreeClick} color="primary">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
