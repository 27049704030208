import { Box, Button, Grid, Typography } from '@mui/material';
import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';

export default function IndexPageAddProjectButtonsRow({canAddProject, canAddProjectWithoutCost}){
  const { t } = useTranslation();
  return (
    <>
      <Grid
        container={true}
        spacing={3}
      >
        <Grid
          item={true}
          lg={2}
          md={2}
          xs={2}
        >
        </Grid>
        <Grid
          item={true}
          lg={10}
          md={10}
          xs={10}
          align={'right'}
        >
          <Box sx={{ mr: 1 }}>
            {canAddProject &&
              <>
                <NextLink href="/project/add">
                  <Button
                    color="primary"
                    variant="contained"
                  >
                    {t('add_large_project')}
                  </Button>
                </NextLink>
              </>
            }
            {canAddProjectWithoutCost &&
              <>
                <NextLink href="/project/add/without-cost">
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ml: 1}}
                  >
                    {t('add_project_without_cost')}
                  </Button>
                </NextLink>
              </>
            }
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
