import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export default function  TotalCostedProjects({props}){
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const router = useRouter()

  const [firstLoad, setFirstLoad] = useState(true)
  const [projectsCount, setProjectsCount] = useState(0)

  function getForCostingProjectCount(){
    let config = {
      method: 'get',
      url: '/api/large-projects/getCountByStatus/2',
      headers: {
        'Authorization': 'Bearer ' + cookies.token
      }
    };

    axios(config)
      .then(function (response) {
        const allResponse = response.data
        setProjectsCount(allResponse['project_count'])
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (firstLoad){
      getForCostingProjectCount()
      setFirstLoad(false)
    }
  })

  return (
    <>
      <Card
        sx={{ height: '100%' }}
        {...props}
        onClick={() => router.push('/projects?page=2').then()}
      >
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'center', alignContent: "center", alignItems: "center" }}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="overline"
                style={{textAlign: "center", display: "block"}}
              >
                {t('large_projects')} - {t('costing_done')}
              </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{textAlign: "center", display: "block"}}
              >
                {projectsCount}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              alignItems: 'center',
              alignContent: "center",
              justifyContent: "center"
            }}
          >
            <Typography
              color="textSecondary"
              variant="caption"
            >
              {t('click_here_to_see_details')}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
